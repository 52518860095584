const unitConverter = {
    
    'unitConverter.title': '单位转换',
    'unitConverter.tip2': '[重置]', 
    'unitConverter.tip1': 'Hpb通常用于其货币的不同面额，例如用于与智能合约交互的Wei和用于计算燃料价格的Gwei。使用我们的单位转换器轻松在它们之间转换！',
     

};

export default unitConverter;
