const address = { 
    'address.title': 'Contract',
    'address.header.contract': 'Contract',
    'address.header.address': 'Address',
    'address.header.tip1': 'Click to view QR Code',

    'address.age.tip1': 'Click to show Datetime Format',
    'address.age.tip2': 'Click to show Age Format',

    'address.index.tab.title1': 'Transactions',
    'address.index.tab.title2': 'Internal Txns',
    'address.index.tab.title3': 'HRC-20 Token Txns',
    'address.index.tab.title4': 'Erc721 Token Txns',
    'address.index.tab.title5': 'Validated Blocks',
    'address.index.tab.title6': 'Validators Set Info',
    'address.index.tab.title7': 'Contract',
    'address.index.tab.title8': 'Events',
    'address.index.tab.title9': 'Analytics',

    'address.transactions.tip1': 'Latest',
    'address.transactions.tip2': ' from a total of',
    'address.transactions.tip3': 'transactions',

    'address.transactions.table.TxnHash': 'Txn Hash',
    'address.transactions.table.Block': 'Block',
    'address.transactions.table.age': 'Age',
    'address.transactions.table.time': 'Date Time (UTC)',
    'address.transactions.table.From': 'From',
    'address.transactions.table.To': 'To',
    'address.transactions.table.value': 'Value',
    'address.transactions.table.txnfee': '[Txn Fee]',

    'address.Internaltx.tip1': 'Latest',
    'address.Internaltx.tip2': 'internal transaction', 
    'address.Internaltx.table.txnHash': 'Parent Txn Hash', 
    'address.Internaltx.table.Block': 'Block', 
    'address.Internaltx.table.age': 'Age',
    'address.Internaltx.table.time': 'Date Time (UTC)',
    'address.Internaltx.table.From': 'From',
    'address.Internaltx.table.To': 'To',
    'address.Internaltx.table.Value': 'Value', 


    'address.Tokentxns.tip1': 'Latest', 
    'address.Tokentxns.tip2': 'Token Transfer Events',  
 
    'address.Tokentxns.table.txnHash': 'Parent Txn Hash',  
    'address.Tokentxns.table.age': 'Age',
    'address.Tokentxns.table.time': 'Date Time (UTC)',
    'address.Tokentxns.table.From': 'From',
    'address.Tokentxns.table.To': 'To',
    'address.Tokentxns.table.Value': 'Value', 
    'address.Tokentxns.table.Token': 'Token', 


    'address.TokentxnsErc721.tip1': 'Latest',  
    'address.TokentxnsErc721.tip2': 'Token Transfer Events',  
 
    'address.TokentxnsErc721.table.txnHash': ' Txn Hash',  
    'address.TokentxnsErc721.table.age': 'Age',
    'address.TokentxnsErc721.table.time': 'Date Time (UTC)',
    'address.TokentxnsErc721.table.From': 'From',
    'address.TokentxnsErc721.table.To': 'To',
    'address.TokentxnsErc721.table.TokenId': 'Token ID', 
    'address.TokentxnsErc721.table.Token': 'Token', 

    'address.Events.tip1': 'Latest', 
    'address.Events.tip2': 'Contract Events', 
    'address.Events.tip3': 'Tip', 
    'address.Events.tip4': 'Logs', 
    'address.Events.tip5': 'are used by developers/external UI providers for keeping track of contract actions and for auditing', 
    'address.Events.table.txnHash': ' Txn Hash',  
    'address.Events.table.Method': 'Method',
    'address.Events.table.Logs': 'Logs',


    'address.overview.contractOverview': 'Contract Overview',
    'address.overview.accountoverview': 'Account Overview',
    'address.overview.overview': 'Overview',
    'address.overview.Balance': 'Balance',
    'address.overview.hpbValue': 'HPB Value',
    'address.token.token': 'Token',

    'address.more.more': 'More Info',
    'address.more.mynametag': 'My Name Tag',
    'address.more.createor': 'Contract Creator',
    'address.more.tokenTracker': 'Token Tracker',
    'address.more.attxn': ' at txn',
    'address.more.NotAvailable': ' Not Available',

    'account.table.address': 'Address',
    'account.table.nameTag': 'Name Tag',
    'account.table.balance': 'Balance',
    'account.table.percentage': 'Percentage',
    'account.table.txnCount': 'Txn Count',


    'address.analytics.timeSeries':'Time Series: HPB Balance',
    'address.analytics.hpbHBalance':'HPB Highest Balance',
    'address.analytics.hpbLBalance':' HPB Lowest Balance',
    'address.analytics.USDHValue':' USD Highest Value',
    'address.analytics.USDLValue':' USD Lowest Value',
    'address.analytics.HPBBalancefor':' HPB Balance for',

    'address.analytics.HPBAccountBalance':' HPB Account Balance',
    'address.analytics.HistoricUSDVal':' Historic USD Val',
    'address.analytics.CurrentUSDVal':' Current USD Val',
    'address.analytics.TxnCount':' Txn Count',

    'address.analytics.htbalance':'HPB Balance',
    'address.analytics.transactions':'Transactions',
    'address.analytics.txnfees':'TxnFees',
    'address.analytics.httransfers':'HPB Transfers',
    'address.analytics.tokentransfers':'Token Transfers',

    'address.analytics.timeSeriesTr':'Time Series: HPB Chain Transactions',
    'address.analytics.HPBTransactionsfor':'HPB Transactions for',
    'address.analytics.HPBTransactions':'HPB Transactions',
    'address.analytics.UniqueOutgoingAddress':'Unique Outgoing Address',
    'address.analytics.UniqueIncomingAddress':'Unique Incoming Address',


    'address.analytics.HTBalanceChart.HPBAccountBalance':'HPB Account Balance',
    'address.analytics.HTBalanceChart.HistoricUSDVal':'Historic USD Val',
    'address.analytics.HTBalanceChart.CurrentUSDVal':'Current USD Val',
    'address.analytics.HTBalanceChart.TxnCount':'Txn Count',
    'address.analytics.HTBalanceChart.USDBalanceValue':'USD Balance Value',

    
    'address.analytics.TxnFees.HPBFeesSpent':'HPB Fees Spent',
    'address.analytics.TxnFees.USDFeesSpent':'USD Fees Spent',
    'address.analytics.TxnFees.HPBFeesUsed':'HPB Fees Used',
    'address.analytics.TxnFees.USDFeesUsed':'USD Fees Used',
    'address.analytics.TxnFees.HPBTransactionFeesfor':'HPB Transaction Fees for ',
    'address.analytics.TxnFees.TimeSeries':'Time Series: HPB Transaction Fees Spent and Used',
    'address.analytics.TxnFees.TotalFeesSpent':'Total Fees Spent (As a Sender)',
    'address.analytics.TxnFees.TotalFeesUsed':' Total Fees Used (As a recipient)',

    'address.analytics.HTTransfers.Sent':'Sent (Out)',
    'address.analytics.HTTransfers.Receive':'Receive (In)',
    'address.analytics.HTTransfers.ValidatorReward':'Validator Reward', 
    'address.analytics.HTTransfers.HPBTransactionFeesfor':'HPB Transfers for',
    'address.analytics.HTTransfers.TimeSeries':'Time Series: HPB Transfers',
    'address.analytics.HTTransfers.TransferAmounts':'Transfer Amounts', 

    'address.analytics.TokenTransfers.TokenTransfers':'Token Transfers',
    'address.analytics.TokenTransfers.TokenContractsCount':'Token Contracts Count',
    'address.analytics.TokenTransfers.OutboundTransfers':'Outbound Transfers', 
    'address.analytics.TokenTransfers.InboundTransfers':'Inbound Transfers',
    'address.analytics.TokenTransfers.UniqueAddressSent':'Unique Address Sent',
    'address.analytics.TokenTransfers.UniqueAddressReceived':'Unique Address Received', 
    'address.analytics.TokenTransfers.HPBTransactionFeesfor':'HPB Transfers for',  
    'address.analytics.TokenTransfers.TimeSeries':'Time Series: Address Token (HRC-20) Transfers', 
    'address.analytics.TokenTransfers.ProTip':'Pro-Tip: Click on the chart data points to view more ', 

    'address.contract.tip1':'Are you the contract creator?', 
    'address.contract.tip2':'Verify and Publish', 
    'address.contract.tip3':' your contract source code today!',  
    'address.contract.tip4':'Code', 
    'address.contract.tip5':'Read Contract', 
    'address.contract.tip6':'Write Contract', 
    'address.contract.tip7':' Contract Source Code Verified', 
    'address.contract.tip8':' Exact Match', 
    'address.contract.tip9':'  This contract was created by the contract code at', 
    'address.contract.tip10':'  Note: We also found another', 
    'address.contract.tip11':'contracts', 
    'address.contract.tip12':' with exact matching byte codes', 
    'address.contract.tip13':'Decompile ByteCode', 
    'address.contract.tip14':'Switch to Opcodes View', 
    'address.contract.tip15':'Similar Contracts', 

    'address.contract.tip16':'Contract Name', 
    'address.contract.tip17':'Compiler Version', 
    'address.contract.tip18':'Optimization Enabled', 
    'address.contract.tip19':'Yes', 
    'address.contract.tip20':'No', 
    'address.contract.tip21':'Other Settings', 
    'address.contract.tip22':'evmVersion', 
    'address.contract.tip23':'license', 
    'address.contract.tip24':'Contract Source Code', 
    'address.contract.tip25':'Solidity', 
    'address.contract.tip26':'Contract ABI', 
    'address.contract.tip27':'Contract Creation Code', 

    'account.tip1': 'A total of ',
    'account.tip2': 'accounts found ',
    'account.tip3': 'Showing the last ',
    'account.tip4': 'top accounts only ',
    'account.tip5': 'Show',
    'account.tip6': 'Records',

};

export default address;
