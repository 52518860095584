const tokensNft = { 
    'tokensNft.title': '不可替代 代币搜索', 
    'tokensNft.table.token': '代币',
    'tokensNft.table.transfersTime': '转移 (24小时)',
    'tokensNft.table.TransfersDay': '转移 (7天)', 
    'tokensNft.tip1': '总共寻获',
    'tokensNft.tip2': '代币合约 ', 
    'tokensNft.tip5': '显示',
    'tokensNft.tip6': '记录',

};

export default tokensNft;
